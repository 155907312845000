<template>
  <footer
    class="max-w-screen-content gap-sm laptop:flex-row laptop:gap-xxl max-laptop:pb-xl flex flex-col items-center justify-center"
  >
    <GButton id="show-cgu" class="self-center" data-role="link" @click="showCgu"
      >Conditions générales d'utilisation</GButton
    >

    <GButton
      id="show-confidentialite"
      data-role="link"
      class="self-center"
      @click="showConfidentiality"
      >Politique de confidentialité</GButton
    >

    <div class="flex h-min items-center justify-center">
      <GLink to="https://info.agenda.org/"> Nous contacter </GLink>
    </div>

    <GDialog ref="dialog" class="dialog-full laptop:dialog-center">
      <header class="p-xs flex justify-end bg-blue-50">
        <GButton @click="dialog.close()">
          <template #icon>
            <Icon name="fa6-solid:x" />
          </template>
        </GButton>
      </header>
      <section class="p-sm">
        <GMarkdown v-if="content" :data="content" />

        <div v-else-if="loading" class="flex justify-center">
          <GSpinner class="text-6xl text-blue-500" />
        </div>

        <div v-else class="flex justify-center">
          <div class="w-form gap-sm flex flex-initial flex-col">
            <p>
              {{ errors.getWording("http", 500, "default") }}
            </p>

            <GButton @click="reload()"> Recharger </GButton>
          </div>
        </div>
      </section>
    </GDialog>
  </footer>
</template>

<script setup>
const dialog = useTemplateRef("dialog");
const errors = useFormError();

const CGU = 1;
const CONFIDENTIALITE = 2;

const type = ref(null);
const cgu = ref(null);
const confidentialite = ref(null);

const loading = ref(true);

const content = computed(() => {
  switch (type.value) {
    case CGU:
      return cgu.value;
    case CONFIDENTIALITE:
      return confidentialite.value;
    default:
      return null;
  }
});

async function reload() {
  switch (type.value) {
    case CGU:
      cgu.value = await loadCgu();
      break;
    case CONFIDENTIALITE:
      cgu.value = await loadConfidentiality();
      break;
    default:
      return null;
  }
}

async function showCgu() {
  dialog.value?.showModal();
  type.value = CGU;
  if (!cgu.value) {
    cgu.value = await loadCgu();
  }
}

function loadCgu() {
  loading.value = true;
  return useHttp
    .get("/v1/document/cgu")
    .then(({ body }) => body)
    .catch(() => null)
    .finally(() => {
      loading.value = false;
    });
}

async function showConfidentiality() {
  dialog.value?.showModal();
  type.value = CONFIDENTIALITE;
  if (!confidentialite.value) {
    confidentialite.value = await loadConfidentiality();
  }
}

function loadConfidentiality() {
  loading.value = true;
  return useHttp
    .get("/v1/document/confidentialite")
    .then(({ body }) => body)
    .catch(() => null)
    .finally(() => {
      loading.value = false;
    });
}
</script>
